.editor-wrapper {
    border: none;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.DraftEditor-root {    
    border: 1px solid var(--chakra-colors-inputGray-400);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 100%;
    min-height: inherit;
    resize: vertical;
    overflow: auto;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    color:#4A4A4A99;
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    padding-top: var(--chakra-space-2);
    padding-bottom: var(--chakra-space-2);
}

.DraftEditor-root:hover {
    border: 1px solid var(--chakra-colors-ZLGreen-300);
}

.has-focus .DraftEditor-root, .has-focus:hover .DraftEditor-root, .editor-wrapper:focus .DraftEditor-root {
    border: 1px solid var(--chakra-colors-ZLGreen-300);
    box-shadow: 0px 0px 0px 2px #81d785;
}

.DraftEditor-editorContainer, .public-DraftEditor-content {
    height: 100%;      
}

.DraftEditor-root:hover .DraftEditor-editorContainer, .editor-wrapper:hover .DraftEditor-root .DraftEditor-editorContainer {
    color: #4A4A4ACC;
}

.has-focus .DraftEditor-root .DraftEditor-editorContainer, .has-focus:hover .DraftEditor-root .DraftEditor-editorContainer, .editor-wrapper:focus .DraftEditor-root .DraftEditor-editorContainer {
    color: #2D3748ff;
}

.margin-left {
    margin-left: 30px;
}

.img-margin img {
    margin: 10px;
}

.img-margin iframe {
    width: 75%;
    height: 400px;
}

.customButton {
    border: 0;
    border-radius: 6px;
    padding-top: 0;
    vertical-align: bottom;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.customButton:hover {
    background: #ECF8F6;
}

.customButtonActive {
    background: #DDFFF4;
}

.buttonWrapper {
    display: inline-block;
}

.preview-wrapper {
    overflow-wrap: break-word;
}

.link {
    color: #208779;
    font-weight: bold;
}

.blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: "Hoefler Text", "Georgia", serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.align-left{
    text-align: left;
}

.align-center{
    text-align: center;
}

.align-right{
    text-align: right;
}

.color-picker {
    display: none;
    transition: opacity .2s;
    position: absolute;
    z-index: 1;
    padding: 10px;
    background-color: #e5e5e5dd;
    border-color: #ddd;
    border-width: 2px;
  }
  
  .color {
    position: relative;
  }
  
  .color:hover .color-picker {
    display: grid;
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
    grid-gap: 10px;
    padding-right: 0;
  }