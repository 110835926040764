button[id*='accordion-button'] > div,
button[id*='accordion-button'] > div > div,
button[id*='accordion-button'] > div > div > a,
button[id*='accordion-button'] > div > div > div,
button[id*='accordion-button'] > div > div > div > a,
.about-button > div,
.about-button > div > div,
.about-button > div > div > a {
    width: 100%
}

button[id*='accordion-button'] > div > div > div, button[id*='accordion-button'] > div > div > div > a,
.about-button > div > div > a {
    display: block;
}

.about-button.icon > div > div:nth-child(1),
button[id*='accordion-button'].icon > div > div > div:nth-child(1) {
    width: unset;
}