.react-flow__edge-path,
.react-flow__node{
    transition-delay: 0s;
    transition-duration: 0.5s;
    will-change: transform;
    transition-timing-function: ease-in-out;
    backface-visibility: hidden;
}

@keyframes ShowNodeOrgChard  {
    0% {opacity: 0;}
    20% {opacity: 0.2;}
    40% {opacity: 0.4;}
    60% {opacity: 0.6;}
    80% {opacity: 0.8;}
    100% {opacity: 1;}
}

.nuBlur  .chakra-modal__content-container,
.nuBlur  .chakra-modal__overlay{
    width: 0;
    height: 0;
}

/*mobile*/

.closeBox{
    animation-delay: 0s;
    animation-duration:1s;
    animation-name: closeBoxAnimation;
    overflow: hidden;
    animation-fill-mode: forwards;
}

.openBoxDelay{
    animation-delay: 1s;
    animation-duration: 2s;
    max-height: 0px;
    animation-name: openBoxAnimation;
    overflow: hidden;
    animation-fill-mode: forwards;

}

.openBox{
    animation-delay: 0s;
    animation-duration: 1s;
    max-height: 0px;
    animation-name: openBoxAnimation;
    overflow: hidden;
    animation-fill-mode: forwards;
}


@keyframes openBoxAnimation {
    0% {
        max-height: 0px;
        display: none;
    }
    1% {
        max-height: 0px;
        display: none;
    }
    100% {
        max-height: 500px;
        display: block;
    }
}

.selectedUser{
    animation-delay: 0s;
    animation-duration: 2s;
    max-height: 500px;
    animation-name: selectedUserAnimation;
    overflow: hidden;
    animation-fill-mode: forwards;

}


@keyframes selectedUserAnimation  {
    0%
    {
        background-color:  #fff;
    }
    50%
    {
        background-color:  var(--chakra-colors-green-100);
    }
    100%     {
        background-color:  #fff;
    }
}

@keyframes closeBoxAnimation  {
    0%
    {
    max-height: 500px;
        display: block;
    }
    99%     {
        max-height: 0px;
        display: block;
    }
    100%     {
        max-height: 0px;
        display: none;
    }
}