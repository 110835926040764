@keyframes firstShow  {
    0% {opacity: 0;}
    40% {opacity: 1;}
    100% {opacity: 1;}
}

@keyframes secondShow  {
    0% {opacity: 0;}
    40% {opacity: 0;}
    55% {opacity: 1;}
    100% {opacity: 1;}
}

@keyframes thirdShow  {
    0% {opacity: 0;}
    60% {opacity: 0;}
    100% {opacity: 1;}
}

.firstAnimationShow {
    animation-name: firstShow;
    animation-duration: 4s;
}

.secondAnimationShow {
    animation-name: secondShow;
    animation-duration: 4s;
}

.thirdAnimationShow {
    animation-name: thirdShow;
    animation-duration: 4s;
}