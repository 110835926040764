.scrollingInto{    
    animation: scrollingInto 6s;
    animation-timing-function: ease-out;
}

@keyframes scrollingInto {
    0% {
        background-color: var(--chakra-colors-ZLGreen-300)
    }

    100% {
        background-color: rgba(0,0,0,0)
    }
}