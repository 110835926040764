.toolbar {
    border: none;
    background: transparent;
    box-shadow: none;
    z-index: 2;
    box-sizing: border-box;
}

.toolbar:after, .toolbar:before {
    border: none;
}