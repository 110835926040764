.zIcon:not(.outline, .nostroke):hover, .zIcon:not(.outline, .nostroke):hover path, .zIcon:not(.outline, .nostroke):hover p, .zIcon.active:not(.outline, .nostroke) path, .zIcon.active:not(.outline, .nostroke) p {
    stroke: #07796A !important;
    fill: #07796A;
}

.zIcon.outline:hover, .zIcon.outline:hover path, .zIcon.outline:hover p, .zIcon.outline.active path, .zIcon.outline.active p {
    stroke: #07796A !important;    
}

.zIcon:not(.nostroke), .zIcon:not(.nostroke), .zIcon:not(.nostroke) p, .zIcon:not(.nostroke) path, .zIcon:not(.nostroke) p {
    stroke: #4A5568 !important;
}

.zIcon.nostroke, .zIcon.nostroke, .zIcon.nostroke p, .zIcon.nostroke path, .zIcon.nostroke p {
    fill: #4A5568 !important;
}

.zIcon.dornachIcon .cls-2, .zIcon.dornachIcon .cls-1 {
    stroke: #747575 !important;
    fill: none !important;
}

.zIcon:hover .cls-2, .zIcon:hover .cls-1, .zIcon.dornachIcon.active .cls-2, .zIcon.dornachIcon.active .cls-1 {
    stroke: #07796A !important;
}