.mention {
    color: #208779;
    text-decoration: none;
}

.mentionSuggestions {
    border-top: 1px solid #eee;
    background: #fff;
    border-radius: 2px;
    cursor: pointer;
    padding-top: 8px;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.mentionSuggestionsEntryContainer {
    display: table;
    width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
    display: table-cell;
    vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
    width: 100%;
    padding-left: 8px;
}

.mentionSuggestionsEntry {
    padding: 7px 10px 3px 10px;
    transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);
}

.mentionSuggestionsEntry:active {
    background-color: #cce7ff;
}

.mentionSuggestionsEntryFocused {
    composes: mentionSuggestionsEntry;
    background-color: #e6f3ff;
}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mentionSuggestionsEntryTitle {
    font-size: 80%;
    color: #a7a7a7;
}