.file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .file-input__label {
    width: 100%;
    height: 100%;    
    display: flex;    
    flex-direction: column;
    border-radius: 4px;    
    font-size: 14px;    
    background-color: transparent;    
    border: 1px dashed var(--chakra-colors-gray-400);
    padding-left: var(--chakra-space-8);
    padding-right: var(--chakra-space-8);
    padding-top: var(--chakra-space-4);
    padding-bottom: var(--chakra-space-4);
  }

  .file-input__label:not(.disabled) {
    cursor: pointer;
  }

  .file-input__label.dragOver{
    background-color: var(--chakra-colors-ZSGreen-200);    
  }

  .dragOver{
    cursor: copy;
  }
  
  .file-input__label:hover:not(.disabled), .file-input__label.dragOver {
    border: 1px dashed var(--chakra-colors-ZSGreen-600);    
  }
